.root {
  position: relative;
  // PC
  @media (min-width: 1036px) {
    align-items: center;
    width: 40%;
    gap: 20px;
    padding: 40px;
  }
  // Mobile
  @media screen and (max-width: 1035px) {
    align-items: center;
    width: 100%;
    gap: 14px;
    padding-bottom: 20px;
  }
}

.rootBorder {
  position: relative;
  border-bottom: 1px dashed #c8c8c8;
}

.rankBenefitDetailItemIndex {
  border-radius: 20px;
  border: 2px solid #00508c;
  padding: 3px 20px;
  font-weight: bold;
  color: #00508c;
}

.rankBenefitImage {
  max-width: 100%;
  height: 150px;
}

.spacer {
  flex: 1;
}

.qualifiedRanks {
  background-color: #f0f0f0;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 10px;
  border-radius: 10px;
  & > div {
    margin-left: 20px;
    gap: 10px;
    & > img {
      width: 40px;
      height: 40px;
    }
  }
}

.pageAnchor {
  position: absolute;
  top: -56px;
}
