.root {
  width: 100%;
  max-width: 980px;
  gap: 20px;
}

.inviteMessage {
  padding: 0 10px;
}

.imagesContainer {
  display: grid;
  gap: 10px;
  // PC
  @media screen and (min-width: 1036px) {
    grid-template-columns: repeat(4, 1fr);
    &.clearSp {
      display: grid !important;
    }
    &.clearPc {
      display: none !important;
    }
  }
  // Mobile
  @media screen and (max-width: 1035px) {
    grid-template-columns: repeat(2, 1fr);
    &.clearSp {
      display: none !important;
    }
    &.clearPc {
      display: grid !important;
    }
  }

  & > div > img {
    width: 100%;
    height: auto;
  }
}

.imageContainer {
  position: relative;
}

.badge {
  position: absolute;
  white-space: pre;
  background-color: #00508c;
  padding: 12px;
  color: white;
  border-radius: 50px;
}

.badgePotion {
  &bottom {
    bottom: -10px;
  }
  &top {
    top: -10px;
  }
  &left {
    left: -10px;
    transform: rotate(-20deg);
  }
  &right {
    right: -10px;
    transform: rotate(20deg);
  }
}

.badgeColor {
  &dark {
    background-color: #00508c;
  }
  &light {
    background-color: #8cb4dc;
  }
}

@media screen and (max-width: 1035px) {
  .badgePotion {
    &left {
      transform: rotate(10deg);
      &.badgeColorlight {
        transform: rotate(-10deg);
      }
    }
    &right {
      &.badgeColordark {
        transform: rotate(-10deg);
      }
    }
  }
}

.report {
  border-bottom: 1px solid;
  width: fit-content;
}
