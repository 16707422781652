.container {
  padding: 80px 0;
  position: relative;
  width: 100%;
  align-items: center;
  background-color: #ffffff;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  & > h3 {
    font-size: 40px;
    color: #00508c;
    font-weight: 700;
  }
  & > h4 {
    font-size: 16px;
    color: #00508c;
    font-weight: 700;
  }
}

.body {
  padding: 0 28px;
  align-items: center;
  gap: 40px;
}

.description {
  color: #00508c;
  font-weight: bold;
  text-align: center;
}

.benefitHeaders {
  display: flex;
  // PC
  @media (min-width: 1036px) {
    flex-direction: row;
    gap: 28px;
  }
  // Mobile
  @media screen and (max-width: 1035px) {
    width: 100%;
    flex-direction: column;
    gap: 14px;
  }
}

.rankBenefitItem:not(:last-child) {
  // Mobile
  @media screen and (max-width: 1035px) {
    border-bottom: 1px dashed #c8c8c8;
    padding: 0px 0 20px;
  }
}

.benefitConditionCard {
  background-color: #f0f0f0;
  border-radius: 10px;
  padding: 10px;
  align-items: center;
  justify-content: center;
  min-width: 308px;
  & > img {
    width: 60px;
    height: 60px;
    margin-right: 10px;
  }
  & > div > p {
    text-wrap: nowrap;
  }
}

.rankThreshold {
  margin: 0 4px;
  line-height: 24px;
}

.rankBenefit {
  display: flex;
  margin-top: 20px;
  align-items: center;
  justify-content: space-between;
}

.rankIndex {
  align-items: center;
  justify-content: center;
  margin-right: 20px;
  margin-bottom: -3px;
  & p {
    color: #00508c;
    line-height: 90%;
  }
}

.rankBenefitDescriptionArrow {
  width: 24px;
  height: 24px;
  background-color: #00508c;
  border-radius: 50%;
  position: relative;
}

.rankBenefitDescriptionArrow::after {
  content: "";
  position: absolute;
  top: 20%;
  left: 50%;
  transform: translate(-50%, 0%) rotate(45deg);
  width: 10px;
  height: 10px;
  border-right: 2px solid white;
  border-bottom: 2px solid white;
}

.rankBenefitDetailContainer {
  // PC
  @media (min-width: 1036px) {
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
    justify-content: center;
  }
  // Mobile
  @media screen and (max-width: 1035px) {
    display: flex;
    flex-direction: column;
    gap: 40px;
  }
}

.borderRight {
  margin: 20px 0px;
  border-right: 1px dashed #c8c8c8;
}

.borderBottom {
  width: 100%;
  border-bottom: 1px dashed #c8c8c8;
}

.alertMessage {
  border: 1px solid #ff6478;
  padding: 20px;
  margin: 40px auto 0;

  // PC
  @media (min-width: 1036px) {
    width: 476px;
  }
  // Mobile
  @media screen and (max-width: 1035px) {
    width: 100%;
  }
}
