$sp-bubble-size: min(max(160px, 45vw), 180px);
$pc-bubble-size: 200px;

.root {
  // PC
  @media (min-width: 1036px) {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  // Mobile
  @media screen and (max-width: 1035px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
  }
}

.titleChip {
  padding: 5px 20px;
  border: 1px solid #5a5a5a;
  border-radius: 30px;
  font-weight: bold;

  // PC
  @media (min-width: 1036px) {
    margin-bottom: 20px;
  }
  // Mobile
  @media screen and (max-width: 1035px) {
    width: auto;
    max-width: fit-content;
  }
}

.chatBubbles {
  // PC
  @media (min-width: 1036px) {
    width: 100%;
    display: flex;
    flex-direction: row;
  }

  // SP
  @media (max-width: 1035px) {
    width: 100%;
    display: flex;
    flex-direction: column;
    & > div {
      width: 100%;
      margin: -3vh 0;
    }
    & > div:nth-child(odd) {
      justify-content: flex-start;
    }
    & > div:nth-child(even) {
      justify-content: flex-end;
    }
  }
}

@mixin chatContainer {
  // PC
  @media (min-width: 1036px) {
    width: $pc-bubble-size;
    height: $pc-bubble-size;
    position: relative;
    align-items: center;
    justify-content: center;
    padding: 30px 24px;
  }
  // SP
  @media (max-width: 1035px) {
    width: $sp-bubble-size;
    height: $sp-bubble-size;
    position: relative;
    align-items: center;
    justify-content: center;
    padding: 20px;
  }
}

.firstChatContainer {
  @include chatContainer;
}

.secondChatContainer {
  @include chatContainer;
  // PC
  @media (min-width: 1036px) {
    margin-left: -10px;
  }
}

.chatBubble {
  position: absolute;

  // PC
  @media (min-width: 1036px) {
    width: $pc-bubble-size;
  }
  // SP
  @media (max-width: 1035px) {
    width: $sp-bubble-size;
  }
}

.chatBubbleNormal {
  // SP
  @media (max-width: 1035px) {
    transform: rotateY(180deg);
  }
}

.chatBubbleReverse {
  // PC
  @media (min-width: 1036px) {
    transform: rotateY(180deg);
  }
}

.chatContent {
  width: 100%;
  z-index: 1;
  & > p {
    font-size: 15px;
    font-weight: bold;
    color: white;
    white-space: pre-wrap;
    text-align: center;
  }
  // SP
  @media (max-width: 1035px) {
    & > p {
      font-size: 12px;
    }
  }
}

.satisfactionImage {
  margin-left: -10px;
  z-index: 1;

  // PC
  @media (min-width: 1036px) {
    width: $pc-bubble-size;
    height: $pc-bubble-size;
  }
  // SP
  @media (max-width: 1035px) {
    width: $sp-bubble-size;
    height: $sp-bubble-size;
  }
}

.annotation {
  // SP
  @media (max-width: 1035px) {
    width: 40%;
    margin-right: auto;
    margin-top: -8vh !important;
  }
}
